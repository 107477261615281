import { env } from "../env";

//export const API_BASE_URL = 'https://www.gopass.top/api';
export const API_BASE_URL = `${env.apiUrl}/api`;

export const ENDPOINTS = {  
    API_BASE_URL: API_BASE_URL,
    GET_GUESTS: `${API_BASE_URL}/getGuests`,
    CREATE_GUEST: `${API_BASE_URL}/createGuest`,
    VERIFY_GUEST: `${API_BASE_URL}/verify`,
    DELETE_GUEST: `${API_BASE_URL}/deleteGuest`,
    CREATE_PAYMENT: `${API_BASE_URL}/createPayment`,
};