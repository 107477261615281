import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { motion } from 'framer-motion';

const VerifyGuest = () => {
    const { guestId } = useParams();
    const [status, setStatus] = useState('loading');
    const [guestData, setGuestData] = useState(null);
    const [message, setMessage] = useState('Verificando invitado...');
    const [entriesCount, setEntriesCount] = useState(0);  // Aquí tenemos el número de entradas

    useEffect(() => {
        const verifyGuest = async () => {
            try {
                const response = await fetch(`https://www.gopass.top/api/verify/${guestId}`, {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' }
                });

                if (response.ok) {
                    const data = await response.json();
                    setStatus('success');
                    setGuestData(data.guest);
                    setEntriesCount(data.guest.entriesCount);  // Aquí obtenemos la cantidad de entradas del backend
                    setMessage(data.message);
                } else {
                    const errorData = await response.json();
                    if (response.status === 400) {
                        setStatus('warning');
                    } else {
                        setStatus('error');
                    }
                    setMessage(errorData.message || 'Error al verificar el invitado');
                }
            } catch (error) {
                setStatus('error');
                setMessage('Error de conexión al servidor');
            }
        };

        verifyGuest();
    }, [guestId]);

    const statusConfig = {
        loading: {
            icon: "⏳",
            bgColor: "bg-blue-50",
            textColor: "text-blue-800",
            borderColor: "border-blue-200",
            title: "Verificando"
        },
        success: {
            icon: "✅",
            bgColor: "bg-green-50",
            textColor: "text-green-800",
            borderColor: "border-green-200",
            title: "¡Bienvenido!"
        },
        warning: {
            icon: "⚠️",
            bgColor: "bg-yellow-50",
            textColor: "text-yellow-800",
            borderColor: "border-yellow-200",
            title: "Atención"
        },
        error: {
            icon: "❌",
            bgColor: "bg-red-50",
            textColor: "text-red-800",
            borderColor: "border-red-200",
            title: "Error"
        }
    };

    const currentStatus = statusConfig[status] || statusConfig['loading'];

    return (
        <div className="min-h-screen bg-gradient-to-br from-gray-100 to-gray-200 flex items-center justify-center p-4">
            <motion.div 
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ duration: 0.3 }}
                className="w-full max-w-md"
            >
                <div className={`bg-white rounded-2xl shadow-xl overflow-hidden`}>
                    {/* Header */}
                    <div className={`${currentStatus.bgColor} ${currentStatus.textColor} p-6 text-center`}>
                        <span className="text-4xl mb-4 block">{currentStatus.icon}</span>
                        <h1 className="text-2xl font-bold mb-2">{currentStatus.title}</h1>
                        <p className="text-sm opacity-75">{message}</p>
                    </div>

                    {/* Guest Details */}
                    {status === 'success' && guestData && (
                        <div className="p-6">
                            <div className="space-y-4">
                                <div className="text-center">
                                    <h2 className="text-xl font-semibold text-gray-800">
                                        {guestData.name}
                                    </h2>
                                    {guestData.email && (
                                        <p className="text-gray-500 text-sm">{guestData.email}</p>
                                    )}
                                </div>
                                
                                <div className="border-t border-gray-100 pt-4">
                                    <p className="text-center text-sm text-gray-600">
                                    Ingreso registrado: {guestData.enteredAt ? new Date(guestData.enteredAt).toLocaleString() : 'Aún no registrado'}
                                    </p>
                                    <p className="text-center text-sm text-gray-600 mt-2">
                                        Cantidad de entradas: {entriesCount} {entriesCount > 1 ? 'personas' : 'persona'} pueden pasar.
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Footer */}
                    <div className="p-6 bg-gray-50 border-t border-gray-100">
                        <p className="text-center text-sm text-gray-600">
                            Sistema de verificación de invitados
                        </p>
                    </div>
                </div>
            </motion.div>
        </div>
    );
};


export default VerifyGuest;