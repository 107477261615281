import React from 'react';
import { handleWhatsAppClick } from '../hooks/whatsAppRedirect';
import logoGoPass from '../assets/logoGoPass.webp';
import HeaderButton from '../components/HeaderButton';
import Footer from '../components/footer';
export default function PartyGuide() {

  return (
    <>
    <div className="min-h-screen bg-gray-900">
        
        <div className="flex flex-col items-center justify-center w-full py-8">
          <img 
            src={logoGoPass} 
            alt="Logo para fiestas" 
            className="h-24 md:h-32 mb-8"
          />
        </div>
        <div className="max-w-3xl mx-auto px-6 pt-6">
          <HeaderButton />
        </div>
      <div className="max-w-3xl mx-auto p-6 text-white space-y-8">
      
        <section className="space-y-4">
          <h1 className="text-3xl font-bold text-cyan-400">Lo que ofrecemos</h1>
          <p className="text-lg">
            Te ofrecemos una plataforma gratuita para organizar y gestionar tus fiestas. Con nuestra herramienta, tus invitados pueden comprar entradas en cualquier momento del día, sin restricciones de horario.
          </p>
          <p className="text-lg">
            Cada compra se refleja directamente en tu cuenta, y el invitado recibe un correo electrónico con un código QR único que incluye el flyer del evento. Este sistema garantiza la autenticidad de los códigos QR para evitar duplicados.
          </p>
          <p className="text-lg">
            Además, te proporcionamos una lista completa de invitados para que puedas verificar fácilmente la asistencia y mantener un control organizado.
          </p>
        </section>

        <section className="space-y-4">
          <h2 className="text-3xl font-bold text-cyan-400">Check de la fiesta</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <ul className="space-y-2">
              <li>✓ DJ</li>
              <li>✓ Equipo de sonido</li>
              <li>✓ Luces y efectos</li>
              <li>✓ RRPP en el evento</li>
              <li>✓ Baños químicos</li>
              <li>✓ Seguridad</li>
              <li>✓ Control de acceso: pulseras o ticket</li>
              <li>✓ Botiquín de primeros auxilios</li>
              <li>✓ Extintores</li>
              <li>✓ Catering: servicio de comida</li>
              <li>✓ Bebidas</li>
            </ul>
            <ul className="space-y-2">
              <li>✓ Vajillas</li>
              <li>✓ Mesas y sillas</li>
              <li>✓ Carpas o toldos</li>
              <li>✓ Decoración temática</li>
              <li>✓ Photobooth</li>
              <li>✓ Cámara o fotógrafo</li>
              <li>✓ Drones</li>
              <li>✓ Cargadores portátiles</li>
              <li>✓ Equipo de limpieza</li>
              <li>✓ Estacionamiento</li>
              <li>✓ Zona de relax</li>
            </ul>
          </div>
          <p className="text-lg italic">
            Si no tenés alguno de estos elementos, ¡no te preocupes! Nosotros nos encargamos.
          </p>
        </section>

        <button 
          onClick={handleWhatsAppClick}
          className="block w-full py-3 bg-emerald-400 text-black font-bold rounded-lg hover:bg-emerald-300 transition-colors text-xl"
        >
          ¡Quiero esto!
        </button>
      </div>
    </div>
    <Footer />
    </>
  );
}