import React, { useState } from 'react';
import { validateGuestData } from '../../utils/guestHelpers';
import { env } from '../../env';
import { useGuest } from '../../context/GuestContext';

const AddGuest = () => {
    const { fetchGuests } = useGuest();
    const [guestName, setGuestName] = useState('');
    const [guestEmail, setGuestEmail] = useState('');
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [entriesCount, setQuantity] = useState(1);
 
    const handleAddGuest = async () => {
        try {
            setIsLoading(true);
            setError('');
            setSuccessMessage('');
            // Validate the data
            debugger
            const validatedData = validateGuestData(guestName, guestEmail, entriesCount);
            const response = await fetch(`${env.apiUrl}/api/createGuest`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(validatedData),
            });
            if (!response.ok) {
                throw new Error('Error al agregar el invitado');
            }

            // Clear form and show success message
            setSuccessMessage('Invitado agregado y email enviado exitosamente');
            setGuestName('');
            setGuestEmail('');
            fetchGuests(response);
            setTimeout(() => {
                setSuccessMessage('');
            }, 3000);
        } catch (err) {
            setError(err.message || 'Ocurrió un error al agregar el invitado');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="flex flex-col items-center bg-white shadow-md rounded p-6 w-full max-w-md mx-auto">
            <h2 className="text-xl font-bold mb-4">Agregar Invitado</h2>
            <input
                type="text"
                value={guestName}
                onChange={(e) => setGuestName(e.target.value)}
                placeholder="Nombre del invitado"
                className="border border-gray-300 rounded p-2 mb-2 w-full"
                disabled={isLoading}
            />
            <input
                type="email"
                value={guestEmail}
                onChange={(e) => setGuestEmail(e.target.value)}
                placeholder="Correo electrónico"
                className="border border-gray-300 rounded p-2 mb-2 w-full"
                disabled={isLoading}
            />
            <input
                type="number"
                value={entriesCount}
                onChange={(e) => setQuantity(e.target.value)}
                placeholder="Cantidad entradas"
                className="border border-gray-300 rounded p-2 mb-2 w-full"
                disabled={isLoading}
            />
            {error && <p className="text-red-500 text-sm">{error}</p>}
            {successMessage && <p className="text-green-500 text-sm">{successMessage}</p>}
            <button 
                onClick={handleAddGuest} 
                className="bg-blue-500 text-white rounded p-2 hover:bg-blue-600 transition duration-200 disabled:bg-gray-400"
                disabled={isLoading}
            >
                {isLoading ? 'Agregando...' : 'Agregar Invitado'}
            </button>
        </div>
    );
};

export default AddGuest;