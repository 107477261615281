import React from 'react';
import { Outlet } from 'react-router-dom'; // Add this import


export const MainLayout = ({ children }) => {
    return (
        <div className="min-h-screen bg-gray-50">
            <nav className="bg-white shadow-sm">
                <div className="container mx-auto px-4 py-3">
                    <h1 className="text-xl font-bold text-gray-800">Sistema de Invitados</h1>
                </div>
            </nav>
            <main className="container mx-auto p-4">
                <Outlet />
            </main>
            <footer className="bg-white border-t">
                <div className="container mx-auto px-4 py-3">
                    <p className="text-center text-gray-600 text-sm">
                        © {new Date().getFullYear()} Sistema de Gestión de Invitados
                    </p>
                </div>
            </footer>
        </div>
    );
};