import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import { useParams } from 'react-router-dom';
import { events } from '../utils/events';
import SoldOut from './soldout';
import Footer from './footer';

function TicketPurchase() {
    const { eventId } = useParams();
    const navigate = useNavigate();
    const [event, setEvent] = useState(null);
    const [quantities, setQuantities] = useState({ tanda1: 0, tanda2: 0 });

    useEffect(() => {
        const eventData = events.find(e => e.idSlug === eventId);
        setEvent(eventData);
    }, [eventId]);

    if (!event) {
        return <div className="min-h-screen bg-zinc-900 text-white p-4 flex items-center justify-center">
            <p>Cargando evento...</p>
        </div>;
    }

    const { title, image, price, date, tanda1, tanda2, tanda3 } = event;

    if (price === 'SOLD OUT') {
        return <SoldOut />;
    }

    const updateQuantity = (tanda, increment) => {
        setQuantities((prev) => ({
            ...prev,
            [tanda]: Math.max(0, prev[tanda] + increment),
        }));
    };
    
    const serviceFee = 1500;
    const total = Math.floor(quantities.tanda1 * tanda1 + quantities.tanda2 * tanda2);
    const totalServiceFee = (quantities.tanda1 + quantities.tanda2) * serviceFee;
    const grandTotal = Math.floor(total + totalServiceFee);
    
    const totalTickets = quantities.tanda1 + quantities.tanda2;

    return (
        <>
            <div className="min-h-screen bg-zinc-900 text-white p-4">
                <div className="max-w-3xl mx-auto">
                    <div className="flex justify-between items-center mb-8">
                        <button 
                            onClick={() => navigate(-1)}
                            className="flex items-center gap-2 text-cyan-400 hover:text-cyan-300 transition-colors"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                            </svg>
                            Volver
                        </button>
                    </div>

                    <h1 className="text-4xl font-bold text-center mb-8">{title}</h1>

                    {/* Main Content */}
                    <div className="grid md:grid-cols-2 gap-6">
                        {/* Event Image */}
                        <div className="relative aspect-square bg-zinc-800 rounded-lg overflow-hidden">
                            <img
                                src={image}
                                alt={title}
                                className="w-full h-full object-cover"
                            />
                        </div>

                        {/* Ticket Selection */}
                        <div className="space-y-6">
                            

                            {/* Tanda 1 */}
                            <div className="bg-zinc-800/50 p-4 rounded-lg">
                                <div className="flex justify-between items-start mb-2">
                                    <div>
                                        <h2 className="text-xl">EARLY TICKET 🔥</h2>
                                        <p className="text-zinc-400">TANDA 1</p>
                                    </div>
                                    <span className="text-emerald-400 text-xl">${tanda1}</span>
                                </div>

                                <div className="flex items-center justify-between bg-zinc-800 rounded p-2">
                                    <button
                                        onClick={() => updateQuantity('tanda1', -1)}
                                        className="p-2 hover:text-emerald-400"
                                    >
                                        <MinusIcon className="h-5 w-5" />
                                    </button>
                                    <span className="text-xl">{quantities.tanda1}</span>
                                    <button
                                        onClick={() => updateQuantity('tanda1', 1)}
                                        className="p-2 hover:text-emerald-400"
                                    >
                                        <PlusIcon className="h-5 w-5" />
                                    </button>
                                </div>

                                <div className="flex justify-between text-zinc-400 text-sm mt-2">
                                    <span>Costo de servicio:</span>
                                    <span>+${serviceFee}</span>
                                </div>
                            </div>

                            {/* Tanda 2 - Disabled */}
                            <div className="bg-zinc-800/50 p-4 rounded-lg opacity-50 cursor-not-allowed">
                                <div className="flex justify-between items-start mb-2">
                                    <div>
                                        <h2 className="text-xl">TANDA 2</h2>
                                    </div>
                                    <span className="text-emerald-400 text-xl">${tanda2}</span>
                                </div>

                                <div className="flex items-center justify-between bg-zinc-800 rounded p-2">
                                    <button
                                        disabled
                                        className="p-2 text-zinc-600"
                                    >
                                        <MinusIcon className="h-5 w-5" />
                                    </button>
                                    <span className="text-xl">0</span>
                                    <button
                                        disabled
                                        className="p-2 text-zinc-600"
                                    >
                                        <PlusIcon className="h-5 w-5" />
                                    </button>
                                </div>

                                <div className="flex justify-between text-zinc-400 text-sm mt-2">
                                    <span>Costo de servicio:</span>
                                    <span>+${serviceFee}</span>
                                </div>
                            </div>

                            <div className="bg-zinc-800/50 p-4 rounded-lg opacity-50 cursor-not-allowed">
                                <div className="flex justify-between items-start mb-2">
                                    <div>
                                        <h2 className="text-xl">TANDA 3</h2>
                                    </div>
                                    <span className="text-emerald-400 text-xl">${tanda3}</span>
                                </div>

                                <div className="flex items-center justify-between bg-zinc-800 rounded p-2">
                                    <button
                                        disabled
                                        className="p-2 text-zinc-600"
                                    >
                                        <MinusIcon className="h-5 w-5" />
                                    </button>
                                    <span className="text-xl">0</span>
                                    <button
                                        disabled
                                        className="p-2 text-zinc-600"
                                    >
                                        <PlusIcon className="h-5 w-5" />
                                    </button>
                                </div>

                                <div className="flex justify-between text-zinc-400 text-sm mt-2">
                                    <span>Costo de servicio:</span>
                                    <span>+${serviceFee}</span>
                                </div>
                            </div>

                            {/* Total and Purchase Button */}
                            <div className="mt-6">
                                <div className="flex flex-col gap-2 mb-4">
                                    <div className="flex justify-between items-center">
                                        <span>Subtotal:</span>
                                        <span>${total}</span>
                                    </div>
                                    <div className="flex justify-between items-center text-zinc-400">
                                        <span>Costo de servicio total:</span>
                                        <span>+${totalServiceFee}</span>
                                    </div>
                                    <div className="flex justify-between items-center text-xl pt-2 border-t border-zinc-700">
                                        <span>Total:</span>
                                        <span className="text-emerald-400 text-2xl">${grandTotal}</span>
                                    </div>
                                </div>

                                <Link
                                    to={`/payment/${eventId}`}
                                    state={{ 
                                        total,
                                        totalServiceFee,
                                        grandTotal,
                                        quantities,
                                        eventId
                                    }}
                                    className={`block w-full py-3 rounded-lg font-bold text-center transition-colors ${
                                        totalTickets > 0 
                                        ? 'bg-emerald-400 text-zinc-900 hover:bg-emerald-500' 
                                        : 'bg-zinc-600 text-zinc-400 cursor-not-allowed'
                                    }`}
                                    onClick={(e) => totalTickets === 0 && e.preventDefault()}
                                >
                                    COMPRAR
                                </Link>
                            </div>
                        </div>
                    </div>

                    {/* Footer Info */}
                    <div className="mt-8 text-center text-zinc-400">
                        <p className="text-xl mb-2">{date}</p>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default TicketPurchase;
