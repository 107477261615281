import React from 'react';
import { handleWhatsAppClick } from '../hooks/whatsAppRedirect';
export const MessageError = () => {
  const handleClose = () => {
    window.location.reload();
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-70">
      <div className="bg-white rounded-xl shadow-2xl p-10 max-w-2xl w-full mx-6 border-2 border-red-100">
        <div className="mb-8">
          <svg className="mx-auto h-24 w-24 text-red-500 animate-pulse" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
          </svg>
        </div>
        
        <h1 className="text-3xl font-extrabold text-gray-900 mb-6 text-center">
          Error en el proceso de pago
        </h1>
        
        <p className="text-lg text-gray-700 mb-8 text-center leading-relaxed">
          Tus datos fueron guardados, pero no pudimos procesar el pago por problemas técnicos de nuestra parte. Por favor, intenta nuevamente. Si el error persiste,
        </p>

        <div className="text-center mb-8">
          <a onClick={handleWhatsAppClick}
            rel="noopener noreferrer"
            className="text-lg text-blue-600 hover:text-blue-800 underline font-bold transition-colors duration-200"
          >
            haga click aquí para contactarnos
          </a>
        </div>

        <div className="flex justify-center space-x-6">
          <button 
            onClick={handleClose}
            className="px-8 py-3 text-gray-700 hover:text-gray-900 font-semibold text-lg transition-colors duration-200 hover:bg-gray-100 rounded-lg"
          >
            Cerrar
          </button>
          <button 
            onClick={handleClose}
            className="px-8 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 font-semibold text-lg transition-colors duration-200 shadow-lg hover:shadow-xl"
          >
            Intentar de nuevo
          </button>
        </div>
      </div>
    </div>
  );
};
