
import { ENDPOINTS } from '../constants/apiEndpoints';

export const guestApi = {
    fetchGuests: async () => {
        const response = await fetch(ENDPOINTS.GET_GUESTS);
        if (!response.ok) {
            throw new Error('Error al obtener los invitados');
        }
        return response.json();
    },

    createGuest: async (guestData) => {
        const response = await fetch(ENDPOINTS.CREATE_GUEST, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(guestData)
        });
        
        if (!response.ok) {
            throw new Error('Error al agregar el invitado');
        }
        return response.json();
    },

    deleteGuest: async (guestId) => {
        const response = await fetch(`${ENDPOINTS.DELETE_GUEST}/${guestId}`, {
            method: 'DELETE'
        });
        if (!response.ok) {
            throw new Error('Error al eliminar el invitado');
        }
        return response.json();
    },

    verifyGuest: async (guestId) => {
        const response = await fetch(`${ENDPOINTS.VERIFY_GUEST}/${guestId}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        });
        return response;
    },
      
};
