import { calculateGuestStats } from '../../utils/guestHelpers';

export const GuestStats = ({ guests }) => {
    const { totalGuests, guestsEntered, guestsPending } = calculateGuestStats(guests);

    return (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
            <div className="bg-blue-50 p-4 rounded-lg">
                <p className="text-sm text-blue-600 mb-1">Total de Invitados</p>
                <p className="text-2xl font-bold text-blue-800">{totalGuests}</p>
            </div>
            <div className="bg-green-50 p-4 rounded-lg">
                <p className="text-sm text-green-600 mb-1">Invitados que Entraron</p>
                <p className="text-2xl font-bold text-green-800">{guestsEntered}</p>
            </div>
            <div className="bg-yellow-50 p-4 rounded-lg">
                <p className="text-sm text-yellow-600 mb-1">Pendientes por Entrar</p>
                <p className="text-2xl font-bold text-yellow-800">{guestsPending}</p>
            </div>
        </div>
    );
};