import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import FormField from '../components/FormField';
import TermsAndConditions from '../components/TermsAndConditions';
import EventSummary from '../components/EventSummary';
import HeaderButton from '../components/HeaderButton';
import { events } from '../utils/events';  
import Footer from '../components/footer'; 
import { env } from '../env';
import { MessageError } from '../components/messageError';
import { initMercadoPago } from '@mercadopago/sdk-react';


const PaymentForm = () => {
  const { eventId } = useParams();
  const location = useLocation();
  const { total, totalServiceFee, grandTotal, quantities } = location.state || {};
  const [event, setEvent] = useState(null);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('Preparando tu pago...');

  useEffect(() => {
    const eventData = events.find(e => e.idSlug === eventId);
    setEvent(eventData);
    initMercadoPago('APP_USR-7f86ce26-2b07-4c5e-b3fd-21e184d6be89');
  }, [eventId]);


 const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setShowError(false);
    try {
      const product = {
        title: event?.title || 'Evento',
        unit_price: 0.01,
        quantity: quantities.tanda1,
      };
      const guestData = {
        name: e.target.name.value,
        email: e.target.email.value,
      };
  
      const response = await fetch(`${env.apiUrl}/api/createPayment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ product, guestData }),
        mode: 'cors', 
      });
  
      if (!response.ok) throw new Error('Error al procesar el pago');
  
      const data = await response.json();
      window.location.href = data.payment_url;
      
    } catch (error) {
      setIsLoading(false);
      setShowError(true);
    }
  };

  const handleTermsChange = (e) => {
    setTermsAccepted(e.target.checked);
  };

  return (
    <>
      <div className="min-h-screen bg-zinc-950 text-white p-4 sm:p-6">
        <HeaderButton />
        {showError && <MessageError />}
        <div className="max-w-6xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8">
          <div className="space-y-6">
            <h2 className="text-xl font-semibold">Tus datos</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
              <FormField 
                label="Nombre"
                id="name"
              />
              <FormField 
                label="Apellido"
                id="surname"
              />
              <FormField 
                label="Email"
                id="email"
                type="email"
              />
              <FormField 
                label="Confirmá tu email"
                id="confirmEmail"
                type="email"
              />
              <FormField 
                label="Tipo de documento"
                id="documentType"
                value="DNI"
                disabled={true}
              />
              <FormField 
                label="Número de identificación"
                id="idNumber"
              />
              <TermsAndConditions checked={termsAccepted} onChange={handleTermsChange} />
              <button
                type="submit"
                disabled={!termsAccepted || isLoading}
                className={`w-full p-2 rounded font-semibold relative ${
                  termsAccepted && !isLoading
                    ? "bg-emerald-400 text-zinc-900 hover:bg-emerald-500" 
                    : "bg-zinc-600 text-zinc-400 cursor-not-allowed"
                }`}
              >
                {isLoading ? (
                  <>
                    <span className="opacity-0">Ir a pagar</span>
                    <div className="absolute inset-0 flex items-center justify-center">
                      <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-white"></div>
                      <span className="ml-2">{loadingMessage}</span>
                    </div>
                  </>
                ) : (
                  "Ir a pagar"
                )}
              </button>
            </form>
          </div>
          <EventSummary 
            event={event} 
            totalServiceFee={totalServiceFee}
            quantities={quantities}
            total={total}
            grandTotal={grandTotal}
          />
        </div>
        {isLoading && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-zinc-800 p-6 rounded-lg shadow-xl flex flex-col items-center">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-emerald-400 mb-4"></div>
              <p className="text-emerald-400 text-lg">{loadingMessage}</p>
              <p className="text-zinc-400 text-sm mt-2">Serás redirigido en unos momentos</p>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default PaymentForm;
