import React, { useEffect, useState } from 'react';
import { useGuest } from '../../context/GuestContext';
import { LoadingSpinner } from '../common/LoadingSpinner';
import { GuestStats } from './GuestStats';
import { QRCodeSVG } from 'qrcode.react';
import { EyeIcon, TrashIcon } from '@heroicons/react/24/outline';
import { ENDPOINTS } from '../../constants/apiEndpoints';
import { guestApi } from '../../api/guestApi';

const BACKEND_URL = ENDPOINTS.API_BASE_URL || 'http://localhost:3000';
const GuestTable = () => {
    const { guests, loading, fetchGuests } = useGuest();

    useEffect(() => {
        fetchGuests();
    }, [fetchGuests]);
    
    const handleDelete = async (guestId) => {
        await guestApi.deleteGuest(guestId);
        fetchGuests(); 
    };

    if (loading) return <LoadingSpinner />;

    return (
        <div className="bg-white rounded-lg shadow-lg p-6 my-6">
            <h2 className="text-2xl font-bold text-gray-800 mb-6">
                Lista de Invitados
            </h2>
            
            <GuestStats guests={guests} />

            <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        <tr>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Nombre
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Correo Electrónico
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Estado
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                QR Code
                            </th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {guests.map(guest => (
                            <GuestTableRow key={guest._id} guest={guest} onDelete={handleDelete} />
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};
const GuestTableRow = ({ guest, onDelete }) => {
    const [showQR, setShowQR] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

    return (
        <tr className="hover:bg-gray-50 transition-colors duration-200">
            <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm font-medium text-gray-900">
                    {guest.name}
                </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-500">
                    {guest.email}
                </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
                <GuestStatus status={guest.hasEntered} />
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex justify-center">
                    <button
                        onClick={() => setShowQR(true)}
                        className="p-2 text-gray-600 hover:text-blue-600 transition-colors duration-200"
                        title="Ver código QR"
                    >
                        <EyeIcon className="h-6 w-6" />
                    </button>
                    <button
                        onClick={() => setShowDeleteConfirm(true)}
                        className="p-2 text-gray-600 hover:text-red-600 transition-colors duration-200"
                        title="Eliminar invitado"
                    >
                        <TrashIcon className="h-6 w-6" />
                    </button>
                </div>

                {showDeleteConfirm && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4 md:p-0">
                        <div className="relative bg-white rounded-lg shadow-xl w-full max-w-sm mx-auto transform transition-all">
                            <button
                                onClick={() => setShowDeleteConfirm(false)}
                                className="absolute top-2 right-2 text-gray-400 hover:text-gray-500 md:hidden"
                            >
                                <span className="sr-only">Cerrar</span>
                                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>

                            <div className="p-4 sm:p-6 md:p-8">
                                <div className="flex flex-col items-center">
                                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100 mb-4">
                                        <TrashIcon className="h-6 w-6 text-red-600" />
                                    </div>
                                    
                                    <h3 className="text-lg sm:text-xl font-medium text-gray-900 mb-2 text-center">
                                        Confirmar Eliminación
                                    </h3>
                                    
                                    <div className="flex flex-col sm:flex-row gap-3 w-full sm:w-auto">
                                        <button
                                            onClick={() => setShowDeleteConfirm(false)}
                                            className="w-full sm:w-auto px-4 py-2 bg-gray-200 hover:bg-gray-300 rounded-md transition-colors duration-200 text-sm sm:text-base order-2 sm:order-1"
                                        >
                                            Cancelar
                                        </button>
                                        <button
                                            onClick={() => {
                                                onDelete(guest._id);
                                                setShowDeleteConfirm(false);
                                            }}
                                            className="w-full sm:w-auto px-4 py-2 bg-red-600 text-white hover:bg-red-700 rounded-md transition-colors duration-200 text-sm sm:text-base order-1 sm:order-2"
                                        >
                                            Eliminar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {showQR && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                        <div className="bg-white p-6 rounded-lg shadow-xl">
                            <div className="flex flex-col items-center">
                                <QRCodeSVG 
                                    value={`${window.location.origin}/verify/${guest._id}`} 
                                    size={200}
                                    level="H"
                                />
                                <p className="mt-2 text-sm text-gray-600">
                                    {guest.name}
                                </p>

                                <button
                                    onClick={() => setShowQR(false)}
                                    className="mt-4 px-4 py-2 bg-gray-200 hover:bg-gray-300 rounded-md transition-colors duration-200"
                                >
                                    Cerrar
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </td>
        </tr>
    );
};

// Componente para el estado del invitado
const GuestStatus = ({ status }) => {
    const statusClasses = status 
        ? 'bg-green-100 text-green-800' 
        : 'bg-yellow-100 text-yellow-800';

    return (
        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${statusClasses}`}>
            {status ? 'Usado' : 'No Usado'}
        </span>
    );
};

export default GuestTable;