import React from 'react';
import AddGuest from '../components/guests/AddGuest';
import GuestTable from '../components/guests/GuestTable';

export const GuestDashboard = () => {
    return (
        <div className="space-y-6">
            <section className="bg-white rounded-lg shadow p-6">
                <AddGuest />
            </section>

            <section className="bg-white rounded-lg shadow">
                <GuestTable />
            </section>
        </div>
    );
};