import funofthesun from "../assets/f.webp";
import highSessions from "../assets/highsessions.webp";
import funinthesunwinter from "../assets/funinthesunwinter.webp";
import mazbe from "../assets/mazbe.webp";
import mazbeParty from "../assets/mazbeParty.webp";


export const events = [
    {
      id: 0,
      idSlug: "mazbe",
      title: "MAZBE",
      organizer: "Mazbe", 
      price: "SOLD OUT", 
      date: "19:00 - 22/03/2024",
      image: mazbe,
    },
    {
      id: 1,
      idSlug: "mazbeParty",
      title: "MAZBE",
      organizer: "Mazbe", 
      price: "SOLD OUT", 
      date: "22:00 - 31/03/2024",
      image: mazbeParty,
    },
    {
      id: 2,
      idSlug: "highSessions",
      title: "HIGH SESSIONS",
      organizer: "Valentin Moia", 
      price: "SOLD OUT", 
      date: "24:00 - 13/07/2024",
      image: highSessions,
      tanda1: 12000,
      tanda2: 20000,
      tanda3: 22000,
    },
    {
      id: 3,
      idSlug: "funInTheSun",
      title: "FUN IN THE SUN",
      organizer: "Arturo Reyes", 
      price: "SOLD OUT",
      date: "14:00 - 07/12/2024",
      image: funofthesun,
      },
    /*
    {
      id: 4,
      idSlug: "funinthesun2",
      title: "FUN IN THE SUN NEW Vol II",
      organizer: "Arturo Reyes", 
      price: "12000", 
      date: "13:00 - 01:00 - 01/02/2025",
      image: funinthesunwinter,
      tanda1: 12000,
      tanda2: 15000,
      tanda3: 20000,
    },
    */

  
  ]