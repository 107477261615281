export const calculateGuestStats = (guests) => {
    const totalGuests = guests.length;
    const guestsEntered = guests.filter(guest => guest.hasEntered).length;
    const guestsPending = totalGuests - guestsEntered;

    return {
        totalGuests,
        guestsEntered,
        guestsPending
    };
};

export const validateGuestData = (name, email, entriesCount) => {
    if (!name.trim() || !email.trim()) {
        throw new Error('Por favor, ingresa un nombre y un correo electrónico válidos.');
    }
    return {
        name: name.trim(),
        email: email.trim(),
        entriesCount: entriesCount
    };
};