import logoGoPass from '../assets/logoGoPass.png';
import { Link } from 'react-router-dom';
import irFiesta from '../assets/irFiesta.webp';
import organizarFiesta from '../assets/organizarFiesta.webp';

export default function PartyOptions() {
    return (
      <div className="min-h-screen bg-black text-white">
        <header className="flex justify-center items-center p-4 sm:p-6">
          <div className="text-xl sm:text-2xl font-bold text-cyan-400">
            <img 
              src={logoGoPass} 
              alt="Go Pass" 
              className="w-48 sm:w-72 md:w-96 h-auto object-contain" 
              width="480" 
              height="100"
              srcSet={`${logoGoPass} 480w, ${logoGoPass} 768w, ${logoGoPass} 1024w`}
              sizes="(max-width: 640px) 192px, (max-width: 768px) 288px, 384px"
            />
          </div>
        </header>

        <div className="container mx-auto px-4 py-8 sm:py-12">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 sm:gap-6 md:gap-8 max-w-5xl mx-auto">
            {/* Sección Organizar Fiesta */}
            <div className="flex flex-col items-center">
              <h2 className="text-xl sm:text-2xl md:text-3xl font-bold mb-3 sm:mb-4 text-center">
                Organiza una Fiesta
              </h2>
              <Link to="/createEvent" className="w-full group">
                <div className="relative overflow-hidden rounded-xl sm:rounded-2xl shadow-[0_8px_30px_rgb(0,0,0,0.12)] 
                              hover:shadow-[0_8px_30px_rgba(34,211,238,0.3)] transition-all duration-300 
                              border-2 border-transparent hover:border-cyan-400">
                  <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black/60 
                                opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                  <img
                    src={organizarFiesta}
                    alt="Organizador de eventos"
                    className="w-full h-[300px] sm:h-[350px] md:h-[400px] object-cover group-hover:scale-105 transition-transform duration-500"
                    width="800"
                    height="600"
                    srcSet={`${organizarFiesta} 400w, ${organizarFiesta} 800w, ${organizarFiesta} 1200w`}
                    sizes="(max-width: 768px) 100vw, 50vw"
                    loading="lazy"
                  />
                  <p className="absolute bottom-2 sm:bottom-4 left-0 right-0 text-center text-white text-base sm:text-lg font-semibold 
                              opacity-0 group-hover:opacity-100 transform translate-y-4 
                              group-hover:translate-y-0 transition-all duration-300">
                    Empieza a crear momentos inolvidables →
                  </p>
                </div>
              </Link>
            </div>
    
            {/* Sección Ir a Fiesta */}
            <div className="flex flex-col items-center">
              <h2 className="text-xl sm:text-2xl md:text-3xl font-bold mb-3 sm:mb-4 text-center">
                Únete a la Fiesta
              </h2>
              <Link to="/eventos" className="w-full group">
                <div className="relative overflow-hidden rounded-xl sm:rounded-2xl shadow-[0_8px_30px_rgb(0,0,0,0.12)] 
                              hover:shadow-[0_8px_30px_rgba(34,211,238,0.3)] transition-all duration-300 
                              border-2 border-transparent hover:border-cyan-400">
                  <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black/60 
                                opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                  <img
                    src={irFiesta}
                    alt="Fiesta con DJ"
                    className="w-full h-[300px] sm:h-[350px] md:h-[400px] object-cover group-hover:scale-105 transition-transform duration-500"
                    width="800"
                    height="600"
                    srcSet={`${irFiesta} 400w, ${irFiesta} 800w, ${irFiesta} 1200w`}
                    sizes="(max-width: 768px) 100vw, 50vw"
                    loading="lazy"
                  />
                  <p className="absolute bottom-2 sm:bottom-4 left-0 right-0 text-center text-white text-base sm:text-lg font-semibold 
                              opacity-0 group-hover:opacity-100 transform translate-y-4 
                              group-hover:translate-y-0 transition-all duration-300">
                    Descubre los mejores eventos →
                  </p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }